import React from "react";
import Layout from "../../components/layout";
import Helmet from "react-helmet";

// Components
import Hero from "../../components/product/scheduling/Hero";
import SchedulingIntro from "../../components/product/scheduling/SchedulingIntro";
import SchedulingFeatures from "../../components/product/scheduling/SchedulingFeatures";
import SchedulingQuote from "../../components/product/scheduling/SchedulingQuote";

const Scheduling = () => (
  <Layout>
    <Helmet>
      <title>Scheduling</title>
      <meta
        name="description"
        content="Design a great onboarding curriculum with your team. Automatically schedule sessions into calendars at a time that suits everyone."
      />
    </Helmet>
    <Hero />
    <SchedulingIntro />
    <SchedulingFeatures />
    <SchedulingQuote />
  </Layout>
);

export default Scheduling;
