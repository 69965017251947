import React from "react";
import { ImageTextSection, TextSection, ImageSection } from "../../layout";

import { Image } from "../../../styledComponents/product/Image";
import { H1, H2, Text } from "@lewisblackwood/component-library";

// Images
import SessionCards from "../../../images/product/session-cards.svg";

const Hero = () => (
  <ImageTextSection>
    <ImageSection>
      <Image src={SessionCards} fitMobile />
    </ImageSection>
    <TextSection hero>
      <H1
        size="small"
        sizeMobile="small"
        mb="xsmall"
        colour="grey"
        style={{ fontWeight: 400 }}
      >
        Scheduling
      </H1>
      <H2 size="xxlarge" sizeMobile="large">
        Collaborate on training up new hires
      </H2>
      <Text size="xlarge" sizeMobile="large">
        Design a great onboarding curriculum with your team. Automatically
        schedule sessions into calendars at a time that suits everyone.
      </Text>
    </TextSection>
  </ImageTextSection>
);

export default Hero;
