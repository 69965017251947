import React from "react";
import {
  Curve,
  ImageTextSection,
  TextSection,
  ImageSection,
  FillMargin
} from "../../layout";
import { ImageAnimated } from "../../../styledComponents/product/Image";
import { H2, Text } from "@lewisblackwood/component-library";

// Images
import CreateSession from "../../../images/product/create-a-session.svg";

const SchedulingIntro = () => (
  <>
    <Curve colour="green" />
    <FillMargin colour="green" />
    <ImageTextSection colour="green">
      <TextSection>
        <H2 size="xlarge" sizeMobile="large">
          Create sessions for every scenario
        </H2>
        <Text size="xlarge" sizeMobile="large">
          Invite your teams to contribute to your curriculum. Help them deliver
          great onboarding experiences, regardless of how busy they are.
        </Text>
      </TextSection>
      <ImageSection>
        <ImageAnimated fitMobile src={CreateSession} />
      </ImageSection>
    </ImageTextSection>
    <FillMargin colour="green" right />
    <Curve colour="green" flipped />
  </>
);

export default SchedulingIntro;
