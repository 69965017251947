import React from "react";
import { Link } from "gatsby";
import {
  GridSection,
  Curve,
  ContentSection,
  FillMargin,
  TextSection
} from "../../layout";
import { H2, Text, TextLink } from "@lewisblackwood/component-library";
import { Avatar } from "../../../styledComponents/Common";

// Images
import Athina from "../../../images/profile-athina.jpg";

const SchedulingQuote = () => (
  <GridSection
    column="1 / 4"
    rows="repeat(5, auto)"
    fullWidth
    columns="minmax(16px, 1fr) auto minmax(16px, 1fr)"
  >
    <Avatar image={Athina} row="2" column="2" />
    <Curve colour="green" row="1 / 4" column="1 / 4" />
    <FillMargin colour="green" />
    <ContentSection colour="green" row="4">
      <TextSection quote>
        <H2 center size="large" sizeMobile="default" mb="xxsmall">
          Athina
        </H2>
        <Text
          center
          bold
          size="xlarge"
          sizeMobile="large"
          mb="large"
          colour="black"
        >
          People Operations Associate, Tractable
        </Text>
        <Text center size="xlarge" sizeMobile="large" mb="large">
          &#8220;We’re hiring people for similar roles and actually having
          everything on Personably, in one place and using the same sessions
          makes a big difference. It helps us coordinate and makes sure we don’t
          miss anyone, or any sessions.&#8221;
        </Text>
        <Text center>
          <Link to="/blog/how-tractable-ramped-up-onboarding-while-scaling-their-business">
            <TextLink colour="green" bold size="large">
              Read Tractable’s story
            </TextLink>
          </Link>
        </Text>
      </TextSection>
    </ContentSection>
    <FillMargin colour="green" right />
    <Curve colour="green" flipped row="5" column="1 / 4 " />
  </GridSection>
);

export default SchedulingQuote;
