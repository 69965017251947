import React from "react";

// Components
import { H2, Text } from "@lewisblackwood/component-library";
import {
  ImageTextSection,
  TextSection,
  GridImageSection,
  CircleBackground
} from "../../layout";
import { ImageAnimated } from "../../../styledComponents/product/Image";

// images
import SessionCard from "../../../images/product/session-card.svg";
import ScheduleCalendar from "../../../images/calendar.svg";

const SchedulingFeatures = () => (
  <>
    <ImageTextSection style={{ marginTop: 40 }}>
      <GridImageSection
        columns="1fr auto auto auto 1fr"
        rows="1fr auto auto auto 1fr"
      >
        <ImageAnimated fitMobile src={SessionCard} column="3 / 3" row="3 / 3" />
        <CircleBackground colour="green" column="2 / 5" row="2 / 5" />
      </GridImageSection>

      <TextSection>
        <H2 size="xlarge" sizeMobile="large" style={{ color: "#24B3B3" }}>
          Flexible scheduling for different session types
        </H2>
        <Text size="xlarge" sizeMobile="large" mb="xxsmall">
          Build up your roles from your different sessions, including:
        </Text>
        <ul style={{ marginTop: 0 }}>
          <li>
            <Text size="xlarge" sizeMobile="large">
              one-off onboarding sessions
            </Text>
          </li>
          <li>
            <Text size="xlarge" sizeMobile="large">
              recurring one-to-ones with managers
            </Text>
          </li>
          <li>
            <Text size="xlarge" sizeMobile="large">
              group sessions for all new joiners with the CEO
            </Text>
          </li>
        </ul>
      </TextSection>
    </ImageTextSection>

    <ImageTextSection>
      <TextSection>
        <H2 size="xlarge" sizeMobile="large" style={{ color: "#24B3B3" }}>
          Schedule sessions with just one click
        </H2>
        <Text size="xlarge" sizeMobile="large">
          Arrange sessions for an exact time or allow Personably to
          automatically find a time when everyone’s free - including the meeting
          room.
        </Text>
      </TextSection>
      <GridImageSection
        columns="1fr auto auto auto 1fr"
        rows="1fr auto auto auto 1fr"
      >
        <ImageAnimated
          fitMobile
          src={ScheduleCalendar}
          column="3 / 3"
          row="3 / 3"
        />
        <CircleBackground colour="green" column="2 / 5" row="2 / 5" />
      </GridImageSection>
    </ImageTextSection>
  </>
);

export default SchedulingFeatures;
